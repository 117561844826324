body {
    margin: 0;
    padding: 0;
}

.form-select {
    font-size: 1rem;
    padding: 15px 6px;
    width: 230px;
}
